import { gql } from '@apollo/client';
import CommonFragments from './common.fragment';

const SAVE_OPPORTUNITY_FRAGMENT = gql`
  fragment saveOpportunity on Opportunity {
    id
    title
    description
    venue
    city
    deadline_date
    start_date
    end_date
    organisation
    street
    type
    genres
    instruments
    target
    experience_level
    languages
    max_age
    min_age
    media
    video_links
  }
`;

export const OPPORTUNITY_PAYMENT_FRAGMENT = gql`
  fragment opportunityPayment on Opportunity {
    payment_details {
      is_used
      cost
      code
      currency
      slots
      title
      description
      filled_slots
      deadline_date
    }
    slots_status {
      code
      slots
    }
    payment_options 
    is_paid
    coupons {
      id
      name
      code
      value
      slots
      is_active
    }
  }
`;

export const OPPORTUNITY_MINI_FRAGMENT = gql`
  fragment opportunityMini on Opportunity {
    id
    title
    slug
    target
    summary
    start_date
    end_date
    deadline_date
    is_virtual
    min_age
    max_age
    types {
      name
    }
    logo {
      name
      path
      placeholder
      thumb
    }
    location {
      ...locationInfo
    }
    approval_type
    organisation {
      id
      slug
      name
      logo {
        thumb
      }
      city {
        name
        country {
          name
        }
      }
    }
    ...opportunityPayment
  }
  ${OPPORTUNITY_PAYMENT_FRAGMENT}
  ${CommonFragments.locationInfo}
`;

export const OPPORTUNITY_CARD_FRAGMENT = gql`
  fragment opportunityCard on Opportunity {
    id
    title
    slug
    description
    summary
    organisation {
      id
      slug
      name
      logo {
        thumb
      }
    }
    logo {
      name
      path
      thumb
      placeholder
      responsive_set {
        path
        width
      }
    }
    genres {
      id
      name
    }
    types {
      id
      name
    }
    user {
      id
    }
    location {
      ...locationInfo
    }
    is_draft
    opp_status
    is_private
    is_virtual
    is_worldwide
    start_date
    end_date
    deadline_date
    space {
      id
    }
   
    ...opportunityPayment
  }
  ${CommonFragments.locationInfo}
  ${OPPORTUNITY_PAYMENT_FRAGMENT}
`;


export const OPPORTUNITY_REVIEWER_FRAGMENT = gql`
  fragment opportunityReviewer on Opportunity {
    reviewers {
      id
      name
      email
      avatar {
        name
        placeholder
        thumb
        path
      }
      role {
        name
        display_name
      }
    }
  }
`;

export const COUPON_MINI = gql`
  fragment couponMini on Coupon {
    id
    name
    code
    value
    slots
    is_active
  }
`;

export const OPPORTUNITY_FULL_DETAILS = gql`
  fragment opportunityDetails on Opportunity {
    ...opportunityCard
    description
    form {
      is_skipped
    }
    instruments {
      id
      name
    }
    languages {
      id
      name
    }
    gallery {
      name
      path
      placeholder
      thumb
    }
    target_countries {
      id
      name
    }
    logo {
      name
      path
      placeholder
      thumb
    }
    summary
    time_visibility {
      start_time
      end_time
      deadline_time
    }
    managers {
      id
      name
      email
      role
      user {
        id
        name
        avatar {
          name
          path
          thumb
          placeholder
        }
      }
    }
    target
    experience_level
    created_at
    max_age
    min_age
    is_private
    is_virtual
    is_draft
    opp_status
    publish_at
    approval_type
    video_links
    time_zone
    ...opportunityPayment
  }
  ${OPPORTUNITY_PAYMENT_FRAGMENT}
  ${OPPORTUNITY_CARD_FRAGMENT}
`;

const OpportunityFragment = {
  saveOpportunity: SAVE_OPPORTUNITY_FRAGMENT,
  opportunityCard: OPPORTUNITY_CARD_FRAGMENT,
  opportunityReviewer: OPPORTUNITY_REVIEWER_FRAGMENT,
  opportunityPayment: OPPORTUNITY_PAYMENT_FRAGMENT,
  opportunityMini: OPPORTUNITY_MINI_FRAGMENT,
  couponMini: COUPON_MINI,
  opportunityDetails: OPPORTUNITY_FULL_DETAILS,
};

export default OpportunityFragment;
